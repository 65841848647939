import React from 'react'
import PropTypes from 'prop-types'

const Footer = props => (
  <footer id="footer" style={props.timeout ? { display: 'none' } : {}}>   
  <div class ="quotations">
  <p>"Happiness can be found, even in the darkest of times, if one only remembers to turn on the light." ― Albus Dumbledore</p>
  <p>“Pain is inevitable. Suffering is optional.”  ― Haruki Murakami</p>
  <p>“Life isn’t a hundred-meter race against your friends, but a lifelong marathon against yourself.” ― Haemin Sunim</p>
  <p>“Words are the source of misunderstandings.” ― Antoine de Saint-Exupéry, The Little Prince</p>
  <p>“Once we accept our limits, we go beyond them.” ― Albert Einstein</p>
  <p>"I think it is possible for ordinary people to choose to be extraordinary." ― Elon Musk</p>
  </div>
<p className="copyright">&copy; Tan Qin Wei Victor 2021 </p>
  </footer>
)


Footer.propTypes = {
  timeout: PropTypes.bool,
}

export default Footer
