import React from 'react'
import PropTypes from 'prop-types'

import avatar from '../images/avatar.jpg'
import blogimage1 from '../images/blogimage1.jpg'
import blogimage2 from '../images/blogimage2.jpg'
import blogimage3 from '../images/blogimage3.jpg'
import image2048 from '../images/2048.jpg'
import imagescary from '../images/scary.jpg'
import imagesuperman from '../images/superman.jpg'
import imagetext from '../images/text.jpg'

class Main extends React.Component {
  render() {

    let close = <div className="close" onClick={() => {this.props.onCloseArticle()}}></div>

    return (
      <div ref={this.props.setWrapperRef} id="main" style={this.props.timeout ? {display: 'flex'} : {display: 'none'}}>

        <article id="about" className={`${this.props.article === 'about' ? 'active' : ''} ${this.props.articleTimeout ? 'timeout' : ''}`} style={{display:'none'}}>
          <h2 className="major">About</h2>
          <span className="image avatar"><img src={avatar} alt="" /></span>
          <h2> Victor Tan </h2>
           <p>Adaptable, motivated and self-driven marketer with cross-disciplinary skills sets involving marketing, analytics,finance, design and strategy. </p> 

          <h2 className="major">Work</h2>

          <h3>Senior Marketing Associate | <a href="https://www.xfers.com/sg/straitsx" target="_blank" rel="noopener noreferrer">Xfers</a></h3>
          <p>Xfers is a licensed major payment institution that enables businesses to accept and send payments seamlessly.</p>

          <h3>Marketing Associate | <a href="https://crypto.com/" target="_blank" rel="noopener noreferrer">Crypto.com</a></h3>
          <p>Crypto.com is a pioneering cryptocurrency payments platform. 
            Crypto.com allows users to buy, sell, exchange, pay with crypto. </p>

          <h3>Head of Marketing | <a href="https://careersocius.com/" target="_blank" rel="noopener noreferrer">CareerSocius</a></h3>
          <p>CareerSocius is a social enterprise that helps job-seekers in
          Singapore maximize their career potential. With a diverse team career experts spanning across various industries, we offer the
          relevant expertise and experience to guide job seekers in their job search.</p>

          <h3>Digital Marketing Executive | <a href="https://fundingsocieties.com/" target="_blank" rel="noopener noreferrer">Funding Societies</a></h3>
          <p>Funding Societies | Modalku is the largest Peer-to-Peer lending platform in Southeast Asia. 
            It provides business financing to SMEs which are crowdfunded by retail and institutional investors.</p>
          
          {close}
        </article>

        <article id="work" className={`${this.props.article === 'work' ? 'active' : ''} ${this.props.articleTimeout ? 'timeout' : ''}`} style={{display:'none'}}>
          <h2 className="major">Digital Projects</h2>

          <div class="acc-wraper">
            <div class="accordion">
               <div><a href="https://victorx.space/2048/"><img src={image2048} alt=""></img><h2>2048 Clone</h2></a></div>
               <div><a href="https://victorx.space/animation/"><img src={imagetext} alt=""></img><h2>Text Animations</h2></a></div>
               <div><a href="https://victorx.space/flappybird/"><img src={imagesuperman} alt=""></img><h2>Flappy Man</h2></a></div>
               <div><a href="https://victorx.space/match3/"><img src={imagescary} alt=""></img><h2>Scary Bejewelled</h2></a></div>
             </div>
           </div>

          {close}
        </article>

        <article id="blog" className={`${this.props.article === 'blog' ? 'active' : ''} ${this.props.articleTimeout ? 'timeout' : ''}`} style={{display:'none'}}>
          <h2 className="major">Blog</h2>
          <div class="accordion">
               <div><a href="https://www.linkedin.com/pulse/my-campus-experience-funding-societies-victor-tan/" target="_blank" rel="noopener noreferrer">
                 <img src={blogimage1} alt=""></img><h2>My campus experience at Funding Societies</h2></a></div>
                <div><a href="https://www.linkedin.com/pulse/how-i-built-my-first-feature-filled-digital-portfolio-victor-tan/" target="_blank" rel="noopener noreferrer">
                <img src={blogimage2} alt=""></img><h2>Building my $1.37 digital portfolio</h2></a></div>
                <div><a href="https://www.linkedin.com/pulse/i-learned-cryptocurrency-creating-my-own-victor-tan/" target="_blank" rel="noopener noreferrer">
                <img src={blogimage3} alt=""></img><h2>I Learned About Cryptocurrency By Creating My Own</h2></a></div>
            </div>


          {close}
        </article>

        <article id="contact" className={`${this.props.article === 'contact' ? 'active' : ''} ${this.props.articleTimeout ? 'timeout' : ''}`} style={{display:'none'}}>
          <h2 className="major">Contact</h2>
          <form action="https://formspree.io/victor.tqw@live.com" method="post">
            <div className="field half first">
              <label htmlFor="name">Name</label>
              <input type="text" name="name"/>
            </div>
            <div className="field half">
              <label htmlFor="email">Email</label>
              <input type="email" name="_replyto"/>
            </div>
            <div className="field">
              <label htmlFor="message">Message</label>
              <textarea name="message" rows="4"></textarea>
            </div>
            <ul className="actions">
              <li><input type="submit" value="Send"/></li>
              <li><input type="reset" value="Reset" /></li>
            </ul>
          </form>
          <ul className="icons">
            <li><a href="mailto:victor.tqw@live.com" className="icon fa fa-envelope"><span className="label">Facebook</span></a></li>
            <li><a href="https://www.facebook.com/victortqw" className="icon fa-facebook"><span className="label">Facebook</span></a></li>
            <li><a href="https://www.linkedin.com/in/victortqw/" className="icon fa-linkedin"><span className="label">LinkedIn</span></a></li>
            <li><a href="https://www.instagram.com/victorxmusic" className="icon fa-instagram"><span className="label">Instagram</span></a></li>
            <li><a href="https://github.com/victorxdev" className="icon fa-github"><span className="label">GitHub</span></a></li>
          </ul>
          {close}
        </article>

      </div>
    )
  }
}

Main.propTypes = {
  route: PropTypes.object,
  article: PropTypes.string,
  articleTimeout: PropTypes.bool,
  onCloseArticle: PropTypes.func,
  timeout: PropTypes.bool,
  setWrapperRef: PropTypes.func.isRequired,
}

export default Main
